import { ProgressBar } from 'react-bootstrap';

function Skill() {
    return (
        <section id="skills" className="skills  section-bg">
            <div className="container">

                <div className="section-title">
                    <h2>Skills</h2>
                </div>

                <div className="row skills-content">

                    <div className="col-lg-6" data-aos="fade-up">
                        <div className="progress">
                            <span className="skill">DevOps <i className="val">85%</i></span>
                            <div className="progress-bar-wrap">
                                <ProgressBar variant="success" now={85} />
                            </div>
                        </div>
                        <div className="progress">
                            <span className="skill">Java <i className="val">83%</i></span>
                            <div className="progress-bar-wrap">
                                <ProgressBar variant="success" now={83} />
                            </div>
                        </div>
                        <div className="progress">
                            <span className="skill">HTML <i className="val">89%</i></span>
                            <div className="progress-bar-wrap">
                                <ProgressBar variant="success" now={89} />
                            </div>
                        </div>



                        <div className="progress">
                            <span className="skill">JavaScript <i className="val">75%</i></span>
                            <div className="progress-bar-wrap">
                                <ProgressBar variant="info" now={75} />
                            </div>
                        </div>



                        <div className="progress">
                            <span className="skill">Project management <i className="val">83%</i></span>
                            <div className="progress-bar-wrap">
                                <ProgressBar variant="success" now={83} />
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                        <div className="progress">
                            <span className="skill">Linux/Unix <i className="val">80%</i></span>
                            <div className="progress-bar-wrap">
                                <ProgressBar variant="success" now={80} />
                            </div>
                        </div>
                        <div className="progress">
                            <span className="skill">PHP <i className="val">76%</i></span>
                            <div className="progress-bar-wrap">
                                <ProgressBar variant="info" now={76} />
                            </div>
                        </div>


                        <div className="progress">
                            <span className="skill">CSS <i className="val">86%</i></span>
                            <div className="progress-bar-wrap">
                                <ProgressBar variant="success" now={86} />
                            </div>
                        </div>
                        <div className="progress">
                            <span className="skill">SQL <i className="val">74%</i></span>
                            <div className="progress-bar-wrap">
                                <ProgressBar variant="warning" now={74} />
                            </div>
                        </div>

                        <div className="progress">
                            <span className="skill">Python <i className="val">63%</i></span>
                            <div className="progress-bar-wrap">
                                <ProgressBar variant="danger" now={63} />
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section >
    )
}

export default Skill