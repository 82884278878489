import React from 'react';
import Footer from './footer';
import Header from './header';
import Main from './main';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./notfound";

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-74176783-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Main />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
