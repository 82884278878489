import About from "./about";
import Contact from "./contact";
import Home from "./home";
import Photos from "./photos";
import Stack from "./stack";
import Quotes from "./quotes";
import Resume from "./resume";
import Skill from "./skill";
import Certificate from "./certificate";

function Main() {
    return (
        <>
            <Home />
            <main id="main">
                <About />
                <Skill />
                <Resume />
                <Certificate />
                <Photos />
                <Stack />
                <Quotes />
                <Contact />
            </main>
        </>
    );
}

// asdsad
export default Main;
