import { Link } from "react-router-dom"

function NotFound() {
    return (
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404">
                    <h1>404</h1>
                </div>
                <h2>Oops! Nothing was found</h2>
                <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.
                    <Link to="/"> Return to homepage</Link>
                </p>
                <div className="notfound-social">
                    <a href="https://www.facebook.com/xocbayar" target="_blank" rel="noreferrer"> <i className="bi bi-facebook"></i></a>
                    <a href="https://www.linkedin.com/in/khosbayar-sandag/" target="_blank" rel="noreferrer"> <i className="bi bi-linkedin"></i></a>
                    <a href="https://www.instagram.com/xocbayar/" target="_blank" rel="noreferrer"> <i className="bi bi-instagram"></i></a>
                </div >
            </div >
        </div >
    )
}

export default NotFound