import GLightbox from 'glightbox';
function Photos() {
    return (
        <section id="photos" className="portfolio ">
            <div className="container">

                <div className="section-title">
                    <h2>Photos</h2>
                    <p>I love to take pictures</p>
                </div>

                <div className="row" data-aos="fade-up">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <ul id="portfolio-flters">
                            <li data-filter="*" className="filter-active">All</li>
                            <li data-filter=".filter-mongolia">Mongolia</li>
                            <li data-filter=".filter-nature">Nature</li>
                            <li data-filter=".filter-city">City</li>
                        </ul>
                    </div>
                </div>

                <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="100">

                    <div className="col-lg-4 col-md-6 portfolio-item filter-city">
                        <div className="portfolio-wrap">
                            <img src="assets/img/gallery/Sydney.png" className="img-fluid" alt="" />
                            <div className="portfolio-links">
                                <a href="assets/img/gallery/Sydney.png" data-gallery="portfolioGallery" className="portfolio-lightbox"
                                    title="Opera House, Sydney, Australia"><i className="bx bx-plus"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 portfolio-item filter-nature filter-mongolia">
                        <div className="portfolio-wrap">
                            <img src="assets/img/gallery/Ikh Uul.jpeg" className="img-fluid" alt="" />
                            <div className="portfolio-links">
                                <a href="assets/img/gallery/Ikh Uul.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox"
                                    title="Ikh Uul, Khuvsgul, Mongolia"><i className="bx bx-plus"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 portfolio-item filter-city">
                        <div className="portfolio-wrap">
                            <img src="assets/img/gallery/Macao.jpeg" className="img-fluid" alt="" />
                            <div className="portfolio-links">
                                <a href="assets/img/gallery/Macao.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox"
                                    title="Italy Colosseo in Macao"><i className="bx bx-plus"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 portfolio-item filter-nature filter-mongolia">
                        <div className="portfolio-wrap">
                            <img src="assets/img/gallery/Khuvsgul.jpeg" className="img-fluid" alt="" />
                            <div className="portfolio-links">
                                <a href="assets/img/gallery/Khuvsgul.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox"
                                    title="Jankhai, Khuvsgul, Mongolia"><i className="bx bx-plus"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 portfolio-item filter-mongolia">
                        <div className="portfolio-wrap">
                            <img src="assets/img/gallery/Naadam.jpeg" className="img-fluid" alt="" />
                            <div className="portfolio-links">
                                <a href="assets/img/gallery/Naadam.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox"
                                    title="Naadam, Mongolia"><i className="bx bx-plus"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 portfolio-item filter-city">
                        <div className="portfolio-wrap">
                            <img src="assets/img/gallery/Singapore.jpeg" className="img-fluid" alt="" />
                            <div className="portfolio-links">
                                <a href="assets/img/gallery/Singapore.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox"
                                    title="Marina Bay Sands Singapore"><i className="bx bx-plus"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 portfolio-item filter-nature filter-mongolia">
                        <div className="portfolio-wrap">
                            <img src="assets/img/gallery/Taria.jpeg" className="img-fluid" alt="" />
                            <div className="portfolio-links">
                                <a href="assets/img/gallery/Taria.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox"
                                    title="Tarian turuu"><i className="bx bx-plus"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 portfolio-item filter-nature">
                        <div className="portfolio-wrap">
                            <img src="assets/img/gallery/Tsagaan kherem.jpeg" className="img-fluid" alt="" />
                            <div className="portfolio-links">
                                <a href="assets/img/gallery/Tsagaan kherem.jpeg" data-gallery="portfolioGallery"
                                    className="portfolio-lightbox" title="Flowers, Great Wall, China"><i className="bx bx-plus"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 portfolio-item filter-nature filter-mongolia">
                        <div className="portfolio-wrap">
                            <img src="assets/img/gallery/Toono.jpeg" className="img-fluid" alt="" />
                            <div className="portfolio-links">
                                <a href="assets/img/gallery/Toono.jpeg" data-gallery="portfolioGallery" className="portfolio-lightbox"
                                    title="Toono, Tuv aimag ,Mongolia"><i className="bx bx-plus"></i></a>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}
export default Photos