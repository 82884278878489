function Footer() {
    return (
        <footer id="footer">
            <div className="container">
                <div className="copyright">
                    <strong><span>xocoo.me</span></strong> &copy; {(new Date().getFullYear())}
                </div>
            </div>
        </footer>
    )
}

export default Footer;