function About() {
    return (
        <section id="about" className="about">
            <div className="container">

                <div className="section-title">
                    <h2>About</h2>
                    <p className="lead about-me">Khosbayar Sandag, DevOps engineer from Mongolia. I am currently studying for Masters in Computer Science at Maharishi International University. I'm an ambitious, flexible team member who loves to learn; computer savvy. I like traveling, photography, hiking, and learning new things about technology.
                    </p>
                </div>

                <div className="row">

                    <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                        <h3>DevOps engineer</h3>
                        <p className="fst-italic">
                            Highly motivated DevOps and Software engineer with 5 years of hands-on experience, working on start-up and enterprise systems. Specialized in back-end development and deployment, CI/CD, Docker, Kubernetes, Linux, and AWS, and worked in the IT industry for more than 10 years.
                        </p>
                        <div className="row">
                            <div className="col-lg-6">
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Website:</strong> <span>www.xocoo.me</span></li>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+641 233 0095</span></li>
                                    <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>Fairfield, USA</span></li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>Master</span></li>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong>
                                        <span>hello@xocoo.me</span>
                                    </li>
                                    <li><i className="bi bi-chevron-right"></i> <strong>Work:</strong> <span>Available</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4" data-aos="fade-right">
                        <img src="assets/img/profile-mg.jpeg" className="img-fluid rounded z-depth-2" alt="100x100" />
                    </div>
                </div>

            </div>
        </section>
    )
}

export default About