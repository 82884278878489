function Resume() {
    return (
        <section id="resume" className="resume">
            <div className="container">

                <div className="section-title">
                    <h2>Resume</h2>
                </div>

                <div className="row">
                    <div className="col-lg-6" data-aos="fade-up">
                        <h3 className="resume-title">Sumary</h3>
                        <div className="resume-item pb-0">
                            <h4>KHOSBAYAR SANDAG</h4>
                            <p><em>Innovative and deadline-driven DevOps | Software engineer with 5 years of experience back-end development and deployment, CI/CD, Docker, Kubernetes, Linux, and AWS, and worked in the IT industry for more than 10 years.</em></p>
                            <ul>
                                <li>Fairfield, Iowa, USA</li>
                                <li>(641) 233-0095</li>
                                <li>hello@xocoo.me</li>
                            </ul>
                        </div>

                        <h3 className="resume-title">Education</h3>
                        <div className="resume-item">
                            <h4>MASTER OF COMPUTER SCIENCE</h4>
                            <h5>2021 - 2024</h5>
                            <p><em>Maharishi International University, IA, USA</em></p>
                            <ul>
                                <li>Learn Leading Edge Technologies</li>
                                <li>Career Focused Master’s Degree Program</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                        <h3 className="resume-title">Professional Experience</h3>
                        <div className="resume-item">
                            <h4>Senior DevOps Engineer</h4>
                            <h5>2021</h5>
                            <p><em>VertexMon LLC, Ulaanbaatar, Mongolia </em></p>
                            <ul>
                                <li>Built REST API service for  <a href="https://shilendans.gov.mn/" target="_blank" rel="noopener noreferrer">Glass account system</a> of the Ministry of Finance of Mongolia; developed back-end microservices; database structure, and logical models.</li>
                                <li>Worked on the online travel platform <a href="https://tapatrip.com/" target="_blank" rel="noopener noreferrer">Tapatrip</a>’s development, production environment is set up with Cloudformation, Elastic Beanstalk, Route53, S3, and RDS on AWS.</li>
                                <li>Created the Docker containers and Kubernetes for managing the development life cycle.</li>
                            </ul>
                        </div>
                        <div className="resume-item">
                            <h4>Full Stack Developer</h4>
                            <h5>2020</h5>
                            <p><em>DigitalMedic LLC, Ulaanbaatar, Mongolia</em></p>
                            <ul>
                                <li>Developed efficient and maintainable software according to business objectives and needs of clients for 3 different projects of Mongolia Ministry of Food, Agriculture, and Light Industry.</li>
                                <li>Developed the front-end architecture using VueJS, created the backend API service using Java Spring-boot and MVC Architecture, and modeled the database structure using MySQL.</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Resume