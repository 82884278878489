function Stack() {
    return (
        <section id="portfolio-details" className="portfolio-details section-bg">
            <div className="container">
                <div className="section-title">
                    <h2>Under the hood</h2>
                </div>
                <div className="row gy-4">
                    <div className="col-lg-8">
                        <div className="portfolio-details-slider swiper">
                            <div className="swiper-wrapper align-items-center">
                                <div className="swiper-slide">
                                    <img src="assets/img/diagram.png" alt="" />
                                </div>

                                <div className="swiper-slide">
                                    <img src="assets/img/diagram.png" alt="" />
                                </div>

                                <div className="swiper-slide">
                                    <img src="assets/img/diagram.png" alt="" />
                                </div>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="portfolio-info">
                            <h3>Technologies</h3>
                            <ul>
                                <li><strong>Plaform</strong>: AWS</li>
                                <li><strong>FrontEnd</strong>: ReactJS</li>
                                <li><strong>CI/CD</strong>: Gitlab</li>
                                <li><strong>Project URL</strong>: <a href="#">www.xocoo.me</a></li>
                            </ul>
                        </div>
                        <div className="portfolio-description">
                            <h2>Portfolio detail</h2>
                            <p>
                                Personal portfolio website. Build in ReactJs, bootstrap, and AWS. Implemented CI/CD in gitlab.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Stack