import ReactJsTyping from 'reactjs-typing-effect';

const listOfString = ['DevOps engineer', 'Software Engineer']

function Home() {
    return (
        <section id="home" className="d-flex flex-column justify-content-center align-items-center">
            <div className="home-container" data-aos="fade-in">
                <h1>Khosbayar Sandag</h1>
                <p>
                    I'm{" "}
                    <ReactJsTyping
                        StringList={listOfString}
                        speed={1500}
                    />
                </p>
            </div>
        </section>
    );
}

export default Home;
