import React, { useState } from "react";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
    data: { subject: "", email: "", phone: "", message: "", name: "" },
    submitted: false,
};

const Contact = () => {
    const [contact, setContact] = useState(initialState);

    const onChange = (e) => {
        return setContact({
            ...contact,
            data: { ...contact.data, [e.target.name]: e.target.value },
        });
    };

    const onSubmit = async () => {
        const correctData = {};
        correctData.MessageTitle = contact.data.subject;
        correctData.Email = contact.data.email;
        correctData.Phone = contact.data.phone;
        correctData.Message = contact.data.message;
        correctData.GuestName = contact.data.name;

        if (
            correctData.MessageTitle.length !== 0 &&
            correctData.Email.length &&
            correctData.Message.length &&
            correctData.GuestName.length
        ) {
            axios
                .post(
                    "https://b66hd6vdmb.execute-api.us-east-1.amazonaws.com/dev/contact",
                    correctData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then(
                    (response) => {
                        setContact({ initialState, submitted: true });
                        toast.success(<span>Thank you for contacting me.</span>);
                    },
                    (error) => {
                        toast.error(<span> I'm sorry. I'll fix that!</span>);
                        console.log("Error", error);
                    }
                );
        } else {
            toast.warning(<span>Please enter required informations!</span>);
        }
    };
    return (
        <section id="contact" className="contact section-bg">
            <div className="container">
                <div className="section-title">
                    <h2>Contact</h2>
                    <p>Thank You for Contacting Me</p>
                </div>
                <div className="row" data-aos="fade-in">
                    <div className="col-lg-5 d-flex align-items-stretch">
                        <div className="info">
                            <div className="address">
                                <i className="bi bi-geo-alt"></i>
                                <h4>Location:</h4>
                                <p>Fairfield, IA 52556</p>
                            </div>
                            <div className="email">
                                <i className="bi bi-envelope"></i>
                                <h4>Email:</h4>
                                <p><a href="mailto:hello@xocoo.me">hello@xocoo.me</a></p>
                            </div>
                            <div className="phone">
                                <i className="bi bi-phone"></i>
                                <h4>Call:</h4>
                                <p>+1 641 233 0095</p>
                            </div>
                            {/* <h4>Social connection</h4>
                            <div className="social">
                                <div className="socials">
                                    <a href="https://www.facebook.com/xocbayar" target="_blank" title="Facebook"><i className="bi bi-facebook"></i></a>
                                </div>
                                <div className="socials">
                                    <a href="https://twitter.com/xocbayar" target="_blank" title="Twitter"><i className="bi bi-twitter"></i></a>
                                </div>
                                <div className="socials">
                                    <a href="https://www.linkedin.com/in/khosbayar-sandag/" target="_blank" title="LinkedIn"><i className="bi bi-linkedin"></i></a>
                                </div>
                                <div className="socials">
                                    <a href="https://www.instagram.com/xocbayar/" target="_blank" title="Instagram"><i className="bi bi-instagram"></i></a>
                                </div>
                            </div> */}
                            {/* <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24085.850203061098!2d-91.98389657599175!3d41.0092545752281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87e66613d91aafab%3A0x8c687cb45a4c0997!2sFairfield%2C%20IA%2C%20USA!5e0!3m2!1sen!2sbg!4v1652852583710!5m2!1sen!2sbg"
                                frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe> */}
                        </div>
                    </div>
                    <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                        <form className="php-email-form">
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Full Name" onChange={onChange} />
                                </div>
                                <div className="form-group col-md-6">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Email Address" onChange={onChange} />
                                </div>
                                <div className="form-group col-md-6">
                                    <input type="text" className="form-control" name="phone" id="phone" placeholder="Phone Number (optional)" onChange={onChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" onChange={onChange} />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" name="message" rows="10" placeholder="Message..." onChange={onChange}></textarea>
                            </div>
                            <div className="text-center">
                                {!contact.submitted && (
                                    <button type="button" className="btn btn-primary" onClick={onSubmit}
                                        disabled={contact.submitted}>Send Message</button>
                                )}
                            </div>
                        </form>
                        <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            // theme="colored"
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                    </div>
                </div>
                <div className="row" >
                    <div className="credits">
                        Template by <a href="https://bootstrapmade.com/" target="_blank" rel="noopener noreferrer">BootstrapMade</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact