// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from "swiper";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";

function Quotes() {
    return (
        <section id="quotes" className="testimonials">
            <div className="container">
                <div className="section-title">
                    <h2>Best quotes inspires you</h2>
                    <p>Finding motivation and inspiration while you're in the midst of the hectic daily grind can be very hard,
                        let alone if a challenge or hurdle is blocking an optimistic outlook. The stressors behind your career, in
                        your relationships, and in life at large can take a mental toll if you're not careful. It's why taking time
                        to look to other inspiring leaders for motivation can help keep the flame of positivity burning bright in
                        your life, at any age.</p>
                </div>
                <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                    <div className="swiper-wrapper">
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={3}
                            pagination={{ clickable: true }}
                            modules={[Pagination, Autoplay]}
                            autoplay={{ delay: 8000 }}
                            className="swiper-pagination"
                        >
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonial-item" data-aos="fade-up">
                                        <p>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                            I have learned not to allow rejection to move me.
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                        <h3>Cicely Tyson</h3>
                                        <h4>American actress.</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonial-item" data-aos="fade-up" data-aos-delay="100">
                                        <p>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                            There is always light. If only we're brave enough to see it. If only we're brave enough to be it.
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                        <h3>Amanda Gorman</h3>
                                        <h4>American poet and activist</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonial-item" data-aos="fade-up" data-aos-delay="200">
                                        <p>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                            Don't save your best for when you think the material calls for it. Always bring your full potential to
                                            every take, and be on top of your job, or they will replace you.
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                        <h3>Gabrielle Union</h3>
                                        <h4>American actress</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonial-item" data-aos="fade-up" data-aos-delay="300">
                                        <p>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                            You can't rely on how you look to sustain you, what sustains us, what is fundamentally beautiful is
                                            compassion; for yourself and your those around you.
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                        <h3>Lupita Nyong'o</h3>
                                        <h4>Kenyan-Mexican actress</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide">
                                    <div className="testimonial-item" data-aos="fade-up" data-aos-delay="400">
                                        <p>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                            Don't sit down and wait for the opportunities to come. Get up and make them.
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        </p>
                                        <h3>Madam C.J Walker</h3>
                                        <h4> African American entrepreneur</h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>

            </div>
        </section>
    )
}
export default Quotes