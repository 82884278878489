function Header() {
    return (
        <aside>
            <div className="d-flex flex-column">
                <header className="logo">
                    <a href="/">
                        <img src="assets/img/logo.png" alt="" className="img-fluid rounded-circle" />
                    </a>
                    <h3 className="text-uppercase text-center">Khosbayar Sandag</h3>
                    <h6 className="text-center text-black-50">DevOps | Software Engineer</h6>
                </header>

                <nav id="navbar" className="nav-menu navbar">
                    <ul>
                        <li><a href="/#home" className="nav-link scrollto active"><i className="bx bx-home"></i> <span>Home</span></a></li>
                        <li><a href="/#about" className="nav-link scrollto"><i className="bx bx-user"></i> <span>About</span></a></li>
                        <li><a href="/#skills" className="nav-link scrollto"><i className="bi bi-bookmark-star"></i> <span>Skill</span></a></li>
                        <li><a href="/#resume" className="nav-link scrollto"><i className="bx bx-file-blank"></i> <span>Resume</span></a></li>
                        <li><a href="/#certificate" className="nav-link scrollto"><i className="bi bi-check2-circle"></i> <span>Certificates</span></a></li>
                        <li><a href="/#photos" className="nav-link scrollto"><i className="bx bx-book-content"></i>
                            <span>Photos</span></a></li>
                        <li><a href="/#quotes" className="nav-link scrollto"><i className="bx bx-server"></i> <span>Quotes</span></a></li>
                        <li><a href="/#contact" className="nav-link scrollto"><i className="bx bx-envelope"></i> <span>Contact</span></a></li>
                    </ul>
                </nav>
            </div>
        </aside>
    );
}

export default Header;
