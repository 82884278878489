import { ProgressBar } from 'react-bootstrap';

function Certificate() {
    return (
        <section id="certificate" class="services section-bg">
            <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>Certificates</h2>
                </div>

                <div class="row">

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                        <div class="icon-box iconbox-blue">
                            <div>
                                <img src="assets/img/certificates/OCI_Architect_Professional.jpeg" className="img-fluid" alt="" />
                            </div>
                            <h5><a href="https://catalog-education.oracle.com/pls/certview/sharebadge?id=9AC1465C227C54B74466BB68D4A71976C76D0707E0F51A1AE23BC8EF6A098DC6" target="_blank">Oracle Cloud Infrastructure 2021 Certified Architect Professional</a></h5>
                            <p>Design for Hybrid Cloud Architecture, Design for Security and Compliance, Implement and Operate Database, Implement and Operate Solutions, Migrate On-Premises Workloads to OCI, Plan and Design Solutions</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                        <div class="icon-box iconbox-orange ">
                            <div>
                                <img src="assets/img/certificates/OCI_Architect_Associate.jpeg" className="img-fluid" alt="" />
                            </div>
                            <h5><a href="https://catalog-education.oracle.com/pls/certview/sharebadge?id=C2CD63BEF0B31EA6C0869FCCEF98AA17A0243C512F7BAA938A7B118299BFEE28" target="_blank">Oracle Cloud Infrastructure 2021 Architect Associate</a></h5>
                            <p>Architecting, Cloud Computing, HA, DR, Security, Fast Connect, Compartments, Identity and Access Management, Load Balancing, Networking,
                                Networking Databases</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
                        <div class="icon-box iconbox-pink">
                            <div>
                                <img src="assets/img/certificates/OCI_Foundations_Associate.jpeg" className="img-fluid" alt="" />
                            </div>
                            <h5><a href="https://education.oracle.com/oracle-cloud-infrastructure-foundations-2021-certified-associate/trackp_OCIBF2021" target="_blank">Oracle Cloud Infrastructure Foundations 2021 Associate</a></h5>
                            <p>Describe Core Solutions On OCI, Discuss Core OCI Services And Cloud Native Services, Explain OCI Pricing, Explain The OCI Security Model And Compliance Structure, Support And Operations, Understand Basic Cloud Concepts</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                        <div class="icon-box iconbox-yellow">
                            <div>
                                <img src="assets/img/certificates/Coursera.png" className="img-fluid" alt="" />
                            </div>
                            <h5><a href="https://www.coursera.org/account/accomplishments/certificate/BUYSY3Y32PR5" target="_blank">Continuous Delivery & DevOps</a></h5>
                            <p>Devops, Continuous Delivery, Unit Testing, Infrastructure As Code, System Testing</p>
                        </div>
                    </div>

                    {/* <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                        <div class="icon-box iconbox-red">
                            <div>
                                <img src="assets/img/certificates/AWS_Developer_Associate.png" className="img-fluid" alt="" />
                            </div>
                            <h5><a href="#" target="_blank">AWS Certified Developer – Associate</a></h5>
                            <p> AWS service APIs, AWS CLI, and SDKs; using containers; and deploying with a CI/CD pipeline</p>
                        </div>
                    </div> */}
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                        <div class="icon-box iconbox-teal">
                            <div>
                                <img src="assets/img/certificates/Coursera.png" className="img-fluid" alt="" />
                            </div>
                            <h5><a href="https://coursera.org/share/bdefde1e13dc5455f72ccfeb919baee9" target="_blank">Programming for Everybody (Getting Started with
                                Python)</a></h5>
                            <p> Python Programming</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                        <div class="icon-box iconbox-teal">
                            <div>
                                <img src="assets/img/certificates/Coursera.png" className="img-fluid" alt="" />
                            </div>
                            <h5><a href="https://coursera.org/share/a810eb92f779339425b4cdf6f7a40ca3" target="_blank">Python Data Structures</a></h5>
                            <p>Python Syntax And Semantics, Data Structure, Tuple, Python Programming</p>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default Certificate